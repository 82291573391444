<template>
  <div>
    <bill-layout>
      <span style="font-size: 22px">해지 신청이 완료되었습니다.</span>
      <form-layout>
        <template #title>
          <i :class="formTitle.microsoftManage.iconClass"></i>
          해지 서비스 정보
        </template>
        <template #content>
          <table-layout>
            <column-layout>
              <template #full-content>
                <ul class="summary-list">
                  <li class="summary-list__item">
                    <span class="summary-list__label">서비스</span>
                    {{ templateState.service }}
                  </li>
                  <li class="summary-list__item">
                    <span class="summary-list__label">도메인</span>
                    {{ templateState.domain }}
                  </li>
                  <li class="summary-list__item">
                    <span class="summary-list__label">라이선스</span>
                    {{ templateState.license.value }}
                  </li>
                </ul>
              </template>
            </column-layout>
          </table-layout>
        </template>
      </form-layout>
      <ul>
        <li class="info-list">해지 신청 후 영업일 기준 2~3일 후에 환불 금액을 정산합니다.</li>
        <li class="info-list">
          가비아는 Microsoft사의 환불 정책에 따라 서비스 시작 당일을 제외한 잔여 일수를 일할 계산하여 환불 금액을
          정산합니다.
        </li>
      </ul>
      <div style="display: flex; justify-content: center; margin-top: 15px">
        <a class="gt-button" ref="manageServiceBtn">서비스 관리</a>
      </div>
    </bill-layout>
  </div>
</template>

<script>
import BillLayout from '@/layouts/BillLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import Vue from 'vue';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';

export default Vue.extend({
  components: { BillLayout, FormLayout, ColumnLayout, TableLayout },
  props: {
    templateState: {
      type: Object,
    },
  },
  data() {
    return {
      formTitle,
    };
  },
  mounted() {
    this.$refs.manageServiceBtn.href = `${process.env.VUE_APP_MY_GABIA_URL}/service#/`;
  },
});
</script>
