
import MicrosoftTerminationResultTemplate from '@/templates/ms365/MicrosoftTerminationResultTemplate.vue';
import Vue from 'vue';
import { getServiceRevokeInfo } from '@/api/proxy/gapi';

export default Vue.extend({
  components: {
    MicrosoftTerminationResultTemplate,
  },
  data() {
    return {
      templateState: {
        service: '',
        domain: '',
        license: '',
      },
      service_info: {
        carve_name: '',
        service_name: '',
      },
    };
  },
  async created() {
    if (!this.$store.state.terminationInfo?.msoffice365) {
      alert('잘못된 접근입니다.');
      window.location.href = process.env.VUE_APP_GABIA_URL;
      return;
    }

    this.templateState = this.$store.state.terminationInfo.msoffice365;

    const serviceId = this.$store.state.goodsInfo.serviceId;

    if (serviceId) {
      await getServiceRevokeInfo(serviceId);
    }

    this.$GlobalLoading.hide();
  },
});
